import { inject } from '@angular/core'
import { User } from '@angular/fire/auth'
import { CanMatchFn, Router } from '@angular/router'
import { NAVIGATES, PARSE_URLS } from '@libs/configs/navigates'
import { AuthService } from '@libs/core/services/firebase/auth.service'
import { UsersService } from '@libs/services/db/users.service'
import { cookie } from '@libs/utils/cookie'
import { of, throwError } from 'rxjs'
import { mergeMap, first, catchError } from 'rxjs/operators'

const ERROR_CODES = {
  auth_unauthorized: 'auth/unauthorized',
  auth_unauthorized_with_invitation: 'auth/unauthorized-with-invitation',
  auth_authorized: 'auth/authorized',
  client_deny: 'auth/client/deny',
  no_user: 'user/empty',
  no_clients: 'client/empty',
  no_verify_email: 'email/unveirified',
}
type ErrorCode = (typeof ERROR_CODES)[keyof typeof ERROR_CODES]

interface ErrorIf {
  code: ErrorCode
}

/**
 * 認証プロバイダーがメールのみで、かつメール認証が済んでない場合は認証ページに飛ばす。そうでなければユーザー作成に飛ばす
 */
const hasOnlyEmailProviderButNotVerified = (user: User) => {
  return user.email && !user.emailVerified && !user.phoneNumber
}

export const guestGuard: CanMatchFn = (_route, _segments) => {
  const authSv = inject(AuthService)

  return authSv.isGuest().pipe(
    first(),
    mergeMap(isGuest => {
      if (!isGuest) {
        return throwError(() => Error('already sign-in'))
      } else {
        return of(true)
      }
    }),
    catchError(() => {
      // 強制ログアウトさせた上でページ表示
      return authSv.signOut().pipe(() => {
        return of(true)
      })
    })
  )
}

export const clientsGuard: CanMatchFn = (_route, segments) => {
  const authSv = inject(AuthService)
  const usersSv = inject(UsersService)
  const router = inject(Router)

  const path = '/' + segments.map(s => s.path).join('/')
  // 特定のパスではガード処理を無視する
  const excludedPaths = [PARSE_URLS.USER_CREATE, PARSE_URLS.EMAIL_CONFIRMATION]

  return authSv.isSignedIn().pipe(
    first(),
    mergeMap(isSignedIn => {
      if (!isSignedIn) {
        // 招待URLでログインしていない場合は、適切な場所にルーティング
        if (window.location.pathname.startsWith(PARSE_URLS.INVITATION)) {
          return throwError(() => ({ code: ERROR_CODES.auth_unauthorized_with_invitation }))
        }
        // 特殊なパスの場合はリダイレクトさせない
        if (!excludedPaths.includes(window.location.pathname)) {
          cookie.set(cookie.KEYS.redirectUrl, window.location.href, { expires: 0.5 })
        }
        return throwError(() => ({ code: ERROR_CODES.auth_unauthorized }))
      }
      return usersSv.getSelf().pipe(
        first(),
        mergeMap(() => {
          return of(true)
        }),
        catchError(() => {
          return authSv.getUser().pipe(
            first(),
            mergeMap(user => {
              if (user && hasOnlyEmailProviderButNotVerified(user)) {
                // メール認証が済んでいないのに、メール認証以外のページに遷移するのを禁止
                if (path !== PARSE_URLS.EMAIL_CONFIRMATION) {
                  return of(router.navigate(NAVIGATES.EMAIL_CONFIRMATION))
                }
                return of(true)
              }
              // firestoreに user が居ない場合でも、特殊なパスのみ通す
              if (excludedPaths.includes(path)) {
                return of(true)
              }
              return throwError(() => ({ code: ERROR_CODES.no_user }))
            })
          )
        })
      )
    }),
    mergeMap(() => {
      // user がnullの場合、上の処理で処理済みになるのでここは確定でuserが存在する
      return of(true)
    }),
    catchError((e: ErrorIf) => {
      switch (e.code) {
        case ERROR_CODES.no_clients:
          return of(router.parseUrl(PARSE_URLS.NO_CLIENTS))
        case ERROR_CODES.auth_unauthorized:
          return of(router.parseUrl(PARSE_URLS.SIGN_IN))
        case ERROR_CODES.auth_unauthorized_with_invitation:
          return of(router.createUrlTree(NAVIGATES.SIGN_UP_WITH_EMAIL, { queryParams: getQueryParams() }))
        case ERROR_CODES.no_user:
          return of(router.parseUrl(PARSE_URLS.USER_CREATE))
        case ERROR_CODES.no_verify_email:
          return of(router.parseUrl(PARSE_URLS.EMAIL_CONFIRMATION))
        default:
          return of(false)
      }
    })
  )
}

const getQueryParams = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const queryParams: { [key: string]: string } = {}
  if (searchParams.size === 0) {
    return queryParams
  }
  for (const [key, value] of searchParams.entries()) {
    queryParams[key] = value
  }
  return queryParams
}
